@import "./colors.less";

.layout-content {
  margin: 0 auto;
}

header {
  width: 100%;
}

.layout-header {
  padding-top: 10;
  padding-bottom: 60;
  box-shadow: "0px 1px #f2f2f2";
  z-index: 10;
}

.header-homepage {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  width: 100%;

  .menu {
    flex: 1;

    .ant-menu {
      background-color: transparent;
    }
  }
}

.auth-action-wrapper {
  display: flex;
  align-items: center;
}

.btn-cart {
  display: flex;
  align-items: center;
  border: none;
  background: none;
}

.btn-cart-active {
  height: 42px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: none;
  background: @ash400;
  padding: 10px;
}

.footer-container {
  background-color: @white;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 1px solid @ash500;
}

.footer-wrapper {
  background-color: @white;
  margin: 0 auto;

  .footer-logo {
    margin-bottom: 40px;
    object-fit: contain;
    object-position: left;
  }

  .footer-social-media {
    margin-top: 30px;
    display: flex;
    align-items: center;

    a {
      margin-bottom: 0;
      margin-right: 40px;
    }

    a:last-child {
      margin-right: 0px;
    }
  }
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
}

.footer-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  h2 {
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 0;
  }

  a {
    font-size: 14px;
    margin-bottom: 20px;
    transition: 0.5s;

    &:hover {
      opacity: 0.6;
    }
  }

  p:last-child,
  a:last-child {
    margin-bottom: 0;
  }
}

.footer-social-media-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  display: none;
  padding-top: 20px;
  border-top: 1px solid @ash500;
  width: 100%;
}

.site-layout-content {
  min-height: 280px;
  // background: @white;
  margin: 0 auto;
}

.section-header {
  .section-left {
    // flex: 1;

    h3 {
      font-weight: 600;
      font-family: "Poppins", sans-serif;
      font-size: 32px;
      text-align: center;
      margin-bottom: 32px;
    }

    p {
      color: @charcoal400;
      font-size: 16px;
      text-align: center;
    }
  }

  .section-right {
    flex-shrink: 1;
  }
}
