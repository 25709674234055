@import "./colors.less";

.bg-header-homepage {
  background-image: url("./images/header-background.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: 1100px;
  background-position-y: -130px;
}

.header-homepage {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo-wrapper {
    img {
      width: 130px;
    }
  }

  .homepage-menu {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.banner-image {
  object-fit: contain;
  width: 100%;
  height: auto;
}

.header-action-container {
  display: grid;
  gap: 15px;
  align-items: center;
  grid-template-columns: 1fr 2px 1fr 120px;
  // padding: 16px;
  // box-shadow: 0px 12px 24px -8px rgba(0, 0, 0, 0.15),
  //   0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 100px;
  background-color: @white;
}

.separator-vertical {
  width: 1px;
  height: 100%;
  background-color: @ash400;
}

.btn-action {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @primary;
  color: @white;
  border-radius: 100px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.action-btn-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 1px solid @ash400;
  }

  .content {
    margin-left: 10px;
    flex: 1;

    .label {
      font-size: 14px;
      line-height: 150%;
      color: @charcoal400;
    }
    .value-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .value {
      font-size: 16px;
      line-height: 150%;
      font-weight: 600;
      color: @black;
      font-family: "Poppins", sans-serif;
    }
  }
}

.homepage-section-full {
  margin: 0px auto;
  background: #f1f3f5;
}

.homepage-section {
  margin: 0px 0;
  padding: 70px 0;
  position: relative;
}

.slider-actions {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  justify-content: space-between;
  top: 50%;
  transform: translate(0, -90%);

  button:first-child {
    position: relative;
    left: -65px;
    margin-left: 10px;
  }

  button:last-child {
    position: relative;
    right: -55px;
    margin-left: 10px;
  }

  button {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @white;
    border-radius: 100px;
    border: 1px solid @charcoal200;
    transition: 500ms all ease;
  }

  button:hover {
    opacity: 0.7;
  }
}

.top-pick-card {
  position: relative;
  margin-bottom: 50px;
  background: @white;
  border-radius: 16px;
  padding: 16px;
  transition: 0.5s;
  box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0);

  cursor: pointer;

  // span {
  //   position: absolute;
  //   right: 15px;
  //   top: 15px;
  //   padding: 5px 16px;
  //   background-color: @white;
  //   color: @black;
  //   font-weight: 600;
  //   border-radius: 100px;
  // }

  img {
    border-radius: 20px;
    width: 100%;
    height: 180px;
    object-fit: cover;
    margin-bottom: 15px;
  }

  h4 {
    font-weight: 600;
    color: @black;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
  }

  p {
    color: @charcoal400;
  }

  &:hover {
    box-shadow: 0px 20px 36px -8px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }
}

.how-container {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: none;
  gap: 100px;
  align-items: flex-start;
}
.search-bar {
  width: 50%;
  margin-left: auto; 
  margin-right: auto;
  // display: flex;
  margin-bottom: 40px;
}

.how-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.how-index {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #14b5b0;
  border-radius: 15px;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: @white;
  margin-bottom: 40px;
}

.how-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: @charcoal800;
  margin-bottom: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.how-description {
  font-size: 14px;
  line-height: 150%;
  color: @charcoal400;
  text-align: center;
}

.how-image-wrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 32px;

  img {
    width: 120px;
    height: 80px;
    object-fit: contain;
  }
}

.location-slider-wrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.btn-slick {
  width: 42px;
  height: 42px;
  background-color: @primary;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.location-list-container {
  width: 986px;
}

.location-card {
  padding: 20px;
  height: 153px;
  border-radius: 15px;
  margin-right: 20px;
  border: 1px solid @ash400;
  cursor: pointer;

  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: @charcoal800;
    font-family: "Poppins", sans-serif;
  }

  p {
    margin-top: 10px;
    line-height: 150%;
    color: @charcoal400;
  }
}

.location-card-active {
  border: 1px solid @primary;
}

.faq-container {
  p {
    color: @charcoal400;
  }
}

.faqs-link-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.faqs-link {
  padding: 0;
  color: @black;
  font-size: 14px;
  text-decoration: underline !important;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.header-web {
  position: relative;
}

.bottom-banner-wrapper {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-position-x: 200px;
  background-position-y: top;

  h1 {
    font-size: 42px;
    line-height: 120%;
    font-weight: 600;
    color: @white;
    max-width: 60%;
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
  }

  .bottom-banner-link {
    display: flex;
    align-items: center;
    background-color: @white;
    border-radius: 8px;
    padding: 12px 24px;
    color: @primary;

    span {
      font-weight: 600;
      font-size: 18px;
      font-family: "Poppins", sans-serif;
    }

    img {
      margin-right: 10px;
    }
  }
}

.drawer-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawer-menu {
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  a {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.user-info-wrapper {
  display: flex;
  align-items: center;

  .user-info {
    margin-left: 10px;
  }

  span {
    color: @ash800;
  }
}

.user-menu {
  display: flex;
  flex-direction: column;
  a {
    margin-top: 15px;
    font-size: 16px;
  }
}

.user-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.user-info-signin-wrapper {
  text-align: center;
}

.menu-footer-container {
  position: absolute;
  bottom: 20px;
  width: 85%;
}

.btn {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 42px;
  padding: 8px 16px;
  border: none;
  background-color: @primary;
  color: @white;

  // span {
  //   margin-left: 10px;
  // }
}

.btn-black {
  background-color: black !important;
}

.btn-outlined {
  border: none;
  background-color: @ash400;
  color: @black;
}

.section-cta {
  height: 300px;
  width: 100%;
  background-color: @primary;
  display: flex;
  background-image: url("./images/bottom-cta-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.slick-dots {
  width: auto;

  ::before {
    display: none;
  }
}

.title-about-us {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

.about-us-container {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  flex-wrap: wrap;
  align-items: center;
  gap: 100px;

  h3 {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    font-weight: 600;
  }

  p {
    line-height: 200%;
  }
  .about-us-story {
    flex: 1;
  }
  .about-us-item-even {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-us-item-odd {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-us-item-image {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    img {
      width: 350px;
      height: 350px;
      object-fit: cover;
    }
  }
}
