@import "./colors.less";

@media (min-width: 1200px) {
  .footer-wrapper,
  .header-homepage {
    max-width: 1200px;
    // display: grid;
    // align-items: center;
    // grid-template-columns: 1fr;
  }

  .homepage-section {
    max-width: 1200px;
    margin: 0px auto;
    padding: 70px 0;
  }

  .footer-content {
    display: grid;
    grid-template-columns: 2.5fr repeat(3, 1fr);
    grid-template-rows: none;
    align-items: flex-start;
  }

  .footer-section {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .footer-wrapper,
  .header-homepage {
    max-width: 768px;
  }

  .header-web {
    padding: 0 40px;
  }

  .header-web {
    flex-direction: column;
    padding: 0 20px;

    .ant-col {
      max-width: 100%;
    }

    img {
      width: 100%;
    }

    .header-action-wrapper {
      padding: 16px 24px 16px 16px;
      box-shadow: 0px 12px 24px -8px rgba(0, 0, 0, 0.15),
        0px 1px 1px rgba(0, 0, 0, 0.06);
      border-radius: 100px;
      background-color: @white;
      margin-bottom: 20px;
    }

    .action-btn-wrapper {
      width: 100%;
    }

    .content {
      width: 100%;
    }

    .btn-action {
      width: 100%;
      height: 52px;
    }
  }

  .header-homepage {
    padding: 0 0;
    .btn-sidebar {
      border: none;
      background-color: @white;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-menu {
      border: none;
      background-color: @primary;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }
    .logo-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .homepage-menu-tablet {
      width: auto;
    }
  }

  .homepage-section {
    margin: 0px 40px;
    padding: 70px 0;
  }

  .footer-wrapper {
    max-width: 640px;
  }

  .footer-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-items: flex-start;
    gap: 80px;
  }

  .how-container {
    grid-template-columns: none;
    grid-template-rows: repeat(3, 1fr);
    gap: 40px;

    .how-image-wrapper {
      height: auto;
      margin: 0;
      img {
        height: 80%;
        width: 80%;
      }
    }
  }

  .how-image-wrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      width: 350px;
      height: 350px;
      object-fit: contain;
    }
  }

  .location-list-container {
    max-width: 574px;
  }

  .location-card {
    display: inline-table;
  }
}

@media (max-width: 767px) {
  .footer-wrapper,
  .header-homepage,
  .site-layout-content {
    max-width: 100%;
  }

  .header-homepage {
    padding: 0 20px;
    .btn-sidebar {
      border: none;
      background-color: @white;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-menu {
      border: none;
      background-color: @primary;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }
    .logo-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .homepage-menu-tablet {
      width: auto;
    }
  }

  .header-web {
    flex-direction: column;
    padding: 0 20px;

    .ant-col {
      max-width: 100%;
    }

    img {
      width: 100%;
    }

    .header-action-wrapper {
      padding: 16px 24px 16px 16px;
      box-shadow: 0px 12px 24px -8px rgba(0, 0, 0, 0.15),
        0px 1px 1px rgba(0, 0, 0, 0.06);
      border-radius: 100px;
      background-color: @white;
      margin-bottom: 20px;
    }

    .action-btn-wrapper {
      width: 100%;
    }

    .content {
      width: 100%;
    }

    .btn-action {
      width: 100%;
      height: 52px;
    }
  }

  .homepage-section {
    margin: 0px 0px;
    padding: 20px 0;
  }

  .section-odd {
    margin-left: 20px;
    margin-right: 20px;
  }

  .homepage-section-top-pick {
    margin-left: 0px;
    margin-right: 0px;
  }

  .section-header {
    .section-left {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
  }

  .how-container {
    grid-template-columns: none;
    grid-template-rows: repeat(3, 1fr);
    gap: 40px;

    .how-image-wrapper {
      height: auto;
      margin: 0;
      img {
        height: 20%;
        width: 20%;
      }
    }
  }
  .search-bar {
    width: 100%;
  }

  .how-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-col {
      width: 100%;
      max-width: 100%;
    }

    .how-index {
      display: none;
    }

    .how-title {
      text-align: center;
      font-size: 16px;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .how-description {
      text-align: center;
      font-size: 12px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .location-card {
    display: inline-table;

    margin-right: 0;
    width: 100%;
    border: none;
  }

  .location-card-active {
    border: 1px solid @primary;
  }

  .how-image-wrapper {
    width: 100%;
  }

  .footer-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .footer-wrapper {
    max-width: 100%;
  }

  .footer-content {
    display: block;
  }

  .footer-section {
    margin-bottom: 50px;
  }

  .footer-social-media-wrap {
    display: flex;
  }

  .bottom-banner-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px 30px;
    background-position-x: -20px;
    height: auto;

    h1 {
      font-size: 28px;
      line-height: 120%;
      font-weight: 600;
      color: @white;
      max-width: 100%;
      margin-bottom: 40px;
    }

    .bottom-banner-link {
      display: flex;
      align-items: center;
      background-color: @white;
      border-radius: 100px;
      padding: 12px 24px;
      color: @primary;

      span {
        font-weight: 600;
        font-size: 18px;
      }

      img {
        margin-right: 10px;
      }
    }
  }

  .promo-wrapper {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 100;
    background: #ff4a50;
    box-shadow: 0px 10.4px 18.72px -4.16px rgba(0, 0, 0, 0.15),
      0px 0.52px 0.52px rgba(0, 0, 0, 0.06);

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 106%;
      color: white;
      text-align: center;
      max-width: 60px;
      margin: 0;
    }

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 140%;
      text-align: center;
      margin: 0 auto;
      color: white;
      letter-spacing: -0.02em;
    }
  }

  .promo-close {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 100px;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .faq-container {
    .ant-collapse-header {
      font-size: 16px;
      padding: 0px 0px !important;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .grid-order {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    gap: 20px;

    .grid-order-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 23px;
      gap: 2;
      border: 1px solid #d5dce1;
      border-radius: 16px;

      img {
        width: 50px;
        margin-bottom: 10px;
      }

      .modal-title {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .grid-order-item-disabled {
      background: #efefef;
      color: gray;
      cursor: not-allowed;
    }
  }

  .info-order {
    top: 60px;
    text-align: center;
    padding: 8px 15px;
    height: 45px;
    font-size: 11px;
    line-height: 1.4;
  }

  .search-mobile {
    padding-bottom: 4px;
    padding-top: 10px;
    position: sticky;
    background-color: white;
    z-index: 14;
    top: 105px;
  }

  .menu-tabs-group {
    position: sticky;
    top: 154px;
    z-index: 13;
    background: white;
  }

  .menu-tab-item {
    padding: 12px 0;
    span {
      font-size: 12px;
    }
  }

  .menu-tabs-info {
    padding: 10px 0;
    span {
      font-size: 12px;
    }
  }

  .separator-menu {
    height: 60px;
  }

  .separator-menu .separator-content .separator-content-title {
    font-size: 20px;
  }

  .menu-group {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr;
    align-items: flex-start;
    gap: 15;
  }

  .menu-group-items {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr;
    align-items: flex-start;
    gap: 15;
  }

  .menu-item {
    flex-direction: row !important;
    padding: 8px;
    .ant-image {
      width: 80px !important;
      height: 80px !important;
      img {
        width: 80px;
        height: 80px !important;
        margin-right: 8px;
      }
    }
    .menu-info-group {
      padding-left: 16px;
      flex: 1;
      .menu-info > div {
        margin-top: 0 !important;
      }
    }
  }

  .menu-item {
    &:hover {
      box-shadow: none;
      z-index: 1;
    }
  }

  .menu-added-indicator {
    padding: 3px 8px;
    background: black;
    position: absolute;
    right: auto;
    left: 8px;
    top: 8px;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    p,
    div,
    span {
      color: white !important;
    }
  }

  .meal-pack-top {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto auto;
    gap: 5px !important;
    .meal-pack-info {
      grid-column: 1/3;
    }
    .meal-pack-info {
      grid-column: 1/3;
    }
    button {
      width: 100%;
      grid-column: 1/3;
    }
  }

  .checkout-container {
    grid-template-columns: 1fr;
    display: grid;
    padding: 0px 0;
    gap: 10px;
    margin-bottom: 200px;

    h2 {
      display: none;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .checkout-wrapper-left {
      order: 2;
    }

    .checkout-wrapper-right {
      order: 1;
      position: relative;
    }
  }

  .checkout-delivery-timeframes {
    grid-template-columns: 1fr;
  }

  .banner-wrapper {
    flex-direction: column;

    .box-image {
      flex: 1;
      width: 100%;
      img {
        width: 100%;
        height: 220px;
        object-fit: cover;
        border-radius: 15px;
      }
    }
  }

  .ant-modal {
    max-width: calc(100vw - 46px) !important;
  }

  .modal-image {
    max-width: calc(100vw - 20px) !important;
  }

  .modal-image {
    top: 25%;
  }

  .intro-about-us {
    height: 360px;
    padding: 0 20px;
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 14px;
    }
  }

  .title-about-us {
    font-size: 22px;
    padding: 0 20px;
  }

  .about-us-container {
    grid-template-columns: 1fr;
    gap: 20px;
    .about-us-item-even {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .about-us-item-odd {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-direction: column-reverse;
    }
    .about-us-item-image {
      margin-bottom: 40px;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }
    }
    .about-us-story {
      padding: 0 20px;
      text-align: center;
      h3 {
        font-size: 26px;
      }
      p {
        font-size: 14px !important;
      }
    }
  }

  .checkout-wrapper-right {
    top: 0;
  }

  .meal-pack-highlight {
    font-size: 14px;
  }
}

@border-radius-base: 5px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;